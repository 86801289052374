import { normalizeDomainName, isEmpty } from '@/common/helpers';

import { ActionGet, ActionList, Endpoints } from '../api-data';
import { useData } from './util.js';

class FirewallSitesList extends Array {
  /**
   * @param {array} sites A list of sites
   * @param {string} version The Firewall backend version
   */
  constructor(sites, version) {
    super(...sites);

    /**
     * @type {string} The Firewall backend version
     */
    this.version = version;
  }
}

const useFirewallSites = () => {
  return useData({
    endpoint: Endpoints.FirewallSites,
    action: ActionList,
    initialValue: new FirewallSitesList([], ''),
    outputFilter: (output) => {
      const list = new FirewallSitesList([], output?.version);

      if (isEmpty(output)) {
        return list;
      }

      // for legacy WAF interactions
      if (output.data?.output?.activation_status) {
        for (const [domain, isWafEnabled] of Object.entries(
          output.data.output.activation_status,
        )) {
          list.push({
            domain: normalizeDomainName(domain),
            active: isWafEnabled === 1,
            version: output.version,
          });
        }
      }

      // for WAF 2.0 interactions
      if (Array.isArray(output.data)) {
        output.data.forEach((site) => {
          list.push({
            version: site.version,
            domain: normalizeDomainName(site.domain),
            siteId: site.siteId,
            planId: site.planId,
            status: site.status,
            anyCastIP: site.anyCastIP,
            active: site.active,
            upgradePlanId: site.upgradePlanId,
          });
        });
      }

      return list;
    },
  });
};

const useFirewallDomainConnect = ({ domain, shouldExecute }) => {
  if (!shouldExecute) {
    return {};
  }

  return useData({
    endpoint: Endpoints.FirewallDomainConnect,
    params: {
      id: normalizeDomainName(domain),
    },
    action: ActionGet,
    initialValue: {},
    outputFilter: (output) => output,
  });
};

export default useFirewallSites;
export { useFirewallSites, FirewallSitesList, useFirewallDomainConnect };
